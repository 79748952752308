import { Grid, Typography, Divider } from "@mui/material";
import { BlogCard } from "./BlogCard";

export const Main = ({ title, blogs }) => {
  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        "& .markdown": {
          py: 3,
        },
      }}
    >
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />
      {blogs && blogs.map((post) => <BlogCard key={post._id} post={post} />)}

      {blogs?.length === 0 && (
        <Typography variant="h3" color="error" m={2}>
          Oops! No Blogs here
        </Typography>
      )}
    </Grid>
  );
};
