import { Container } from "@mui/material";
import { Markdown } from "../../components/Blog/Markdown";

export const Contact = () => {
  const content = `# Contact Us

  Have questions, suggestions, or just want to say hello? We'd love to hear from you!
  You can connect with us on social media to stay updated on the latest content, announcements, and more:
  
  - [Twitter](https://twitter.com/lastbenchschola)
  - [Instagram](https://www.instagram.com/lastbenchscholar/)
  - [YouTube](https://youtube.com/@lastbenchscholar)
  

If you have any inquiries, feedback, or if there's anything we can assist you with, please don't hesitate to contact us. We strive to respond to all inquiries promptly.
You can mail us at [lastbenchscholar@gmail.com](mailto:lastbenchscholar@gmail.com) or drop a feedback through our Feedback page (footer -> Feedback).

We look forward to hearing from you and connecting with you on your journey of learning and growth.

*Thank you for being part of the LastBenchScholar community.*

**LastBenchScholar Team**
  `;
  return (
    <Container>
      <Markdown>{content}</Markdown>
    </Container>
  );
};
