import { Box } from "@mui/material";
import { Markdown } from "../../components/Blog/Markdown";

export const About = () => {
  const description = `# About Us

  Welcome to LastBenchScholar, your go-to destination for comprehensive learning and personal development. 
  
  ## Our Mission
  
  At LastBenchScholar, we're dedicated to empowering students on their journey of holistic growth. We believe that education goes beyond textbooks, and our platform offers a diverse range of content that spans technical insights, extracurricular passions, and essential life skills.
  
  ## What We Offer
  
  - **Technical Expertise**: Dive into a world of coding tutorials, engineering insights, and the latest tech trends, all designed to expand your knowledge and skills.
  
  - **Passions Beyond Books**: Explore posts on playing musical instruments, engaging in sports, and embarking on thrilling adventures. We're here to support your extracurricular interests.
  
  - **Personal Development**: Discover practical advice on managing finances, mastering time management, and achieving mental peace amidst the challenges of student life.

## Our Community

Join a vibrant community of like-minded individuals who share the same passion for learning and growth. Engage in discussions, share experiences, and connect with fellow LastBenchScholars who understand the journey.

## Get Involved

We welcome contributions from students, educators, and anyone who's passionate about empowering the next generation. If you have insights, experiences, or knowledge to share, consider becoming a contributor and making a difference in students' lives.

Thank you for being part of the LastBenchScholar community. Let's learn, grow, and thrive together!

*Stay curious, stay inspired, and stay empowered!*
  `;
  return (
    <Box>
      <Markdown>{description}</Markdown>
    </Box>
  );
};
