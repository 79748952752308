import { Box, Typography } from "@mui/material";

export const LandingSection = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: { xs: "250px", sm: "600px" },
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        userSelect: "none",
      }}
    >
      <Typography
        variant="h3"
        component="h2"
        textAlign="center"
        sx={{
          typography: { xs: "h6", sm: "h5", md: "h4", lg: "h3" },
          "@media screen and (max-width: 360px)": {
            fontWeight: 400,
          },
        }}
      >
        Want to learn and have fun at the same time?
      </Typography>
      <Typography
        variant="h4"
        component="h4"
        sx={{ typography: { xs: "subtitle1", sm: "h6", md: "h5", lg: "h4" }, mt: 1 }}
      >
        Welcome to
      </Typography>
      <Typography
        variant="h1"
        component="h1"
        sx={{
          typography: { xs: "h4", sm: "h3", md: "h2", lg: "h1" },
          "@media screen and (max-width: 360px)": {
            fontSize: "1.84rem",
            lineHeight: 1,
          },
        }}
      >
        Last Bench Scholar
      </Typography>
      <Typography
        variant="h4"
        component="h6"
        textAlign="center"
        sx={{ typography: { xs: "subtitle1", sm: "h6", md: "h5", lg: "h4" } }}
      >
        Unleasing Potential, Empowering Growth
      </Typography>
    </Box>
  );
};
