import { Box, Container, Typography, Link, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
  const navigate = useNavigate();
  const footLinks = [
    { title: "About", href: "/about" },
    { title: "Contact", href: "/contact" },
    { title: "Feedback", href: "/feedback" },
    { title: "Privacy", href: "/privacy" },
  ];
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: "background.paper",
        borderTop: 1,
        borderColor: "divider",
        mt: 5,
        mb: 5,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          pt: 2,
        }}
      >
        <Stack direction="row" spacing={2}>
          {footLinks &&
            footLinks.map((item) => (
              <Typography
                key={item.title}
                variant="subtitle1"
                align="center"
                color="text.secondary"
                component="p"
                sx={{
                  ":hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  navigate(item.href);
                }}
              >
                {item.title}
              </Typography>
            ))}
        </Stack>

        {/* <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
          onClick={() => navigate("/create")}
          sx={{ cursor: "pointer" }}
        >
          Create Blog
        </Typography> */}

        <Typography variant="body2" color="text.secondary" align="center">
          {"Copyright © "}
          <Link color="inherit" href={process.env.REACT_APP_HOST_URL}>
            LastBenchScholar
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>
    </Box>
  );
};
