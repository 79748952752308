import { Box, Link, Typography } from "@mui/material";
import NotFoundLogo from "../../assets/notFound.svg";

export const NotFound = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", m: 5 }}>
      <img src={NotFoundLogo} alt="Not Found" width="200rem" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          ml: 10,
        }}
      >
        <Typography variant="h4" color="error">
          404 Page Not Found
        </Typography>
        <Typography variant="subtitle">
          Go to the{" "}
          <Link href="/" sx={{ color: "black" }}>
            Home Page
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
