import { Grid, Link, Paper, Stack, Typography } from "@mui/material";
import {
  GitHub as GitHubIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
} from "@mui/icons-material";

export const Sidebar = () => {
  const sidebar = {
    title: "Here's what you'll find",
    description:
      "Explore a world of knowledge and inspiration at LastBenchScholar – a hub for students seeking holistic growth. From technical insights to personal development, embark on a journey of learning, empowerment, and personal transformation.",
    social: [
      {
        name: "GitHub",
        icon: GitHubIcon,
        href: "https://github.com/RaulLit/Blogging-Frontend",
      },
      { name: "Twitter", icon: TwitterIcon, href: "https://twitter.com/lastbenchschola" },
      {
        name: "Instagram",
        icon: InstagramIcon,
        href: "https://www.instagram.com/lastbenchscholar/",
      },
    ],
  };

  const { description, social, title } = sidebar;

  return (
    <Grid item xs={12} md={4}>
      <Paper elevation={0} sx={{ p: 2, bgcolor: "grey.200" }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Typography>{description}</Typography>
      </Paper>
      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        Social
      </Typography>
      {social.map((network) => (
        <Link
          display="block"
          variant="body1"
          href={network.href}
          key={network.name}
          sx={{ mb: 0.5 }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <network.icon />
            <span>{network.name}</span>
          </Stack>
        </Link>
      ))}
    </Grid>
  );
};
