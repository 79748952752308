import { Send } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const sxField = {
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
  display: "block",
  background: (theme) => theme.palette.common.white,
};
export const FeedbackForm = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Schema
  const schema = yup.object().shape({
    name: yup.string().max(100).required("Title is required!"),
    email: yup.string().max(1000).required("Email is required!"),
    message: yup.string().max(5000).required("Content is required!"),
  });

  // useForm
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCreate = (data) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/blogs/`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setError(null);
        setSuccess("Feedback submitted successfully :)");
        reset();
      })
      .catch((err) => setError(err.message));
  };
  return (
    <Box sx={{ width: { xs: "auto", sm: "30rem" } }}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(handleCreate)}>
        <TextField
          sx={sxField}
          label="Name"
          variant="standard"
          color="primary"
          fullWidth
          required
          error={errors.name ? true : false}
          helperText={errors.name?.message}
          {...register("name")}
        />
        <TextField
          sx={sxField}
          label="Email"
          variant="standard"
          color="primary"
          fullWidth
          required
          error={errors.email ? true : false}
          helperText={errors.email?.message}
          {...register("email")}
        />
        <TextField
          sx={sxField}
          label="Message"
          variant="standard"
          color="primary"
          multiline
          rows={5}
          fullWidth
          required
          error={errors.message ? true : false}
          helperText={errors.message?.message}
          {...register("message")}
        />

        <Button type="submit" color="primary" variant="contained" endIcon={<Send />}>
          Submit
        </Button>
      </form>
      {error && <Typography color="error">{error}</Typography>}
      {success && (
        <Typography
          sx={{
            color: (theme) => theme.palette.common.white,
            background: (theme) => theme.palette.success.light,
            width: "fit-content",
            p: 1,
            mt: 1,
            mb: 1,
            borderRadius: 1,
          }}
        >
          {success}
        </Typography>
      )}
    </Box>
  );
};
