import { Box, Card, CardActionArea, CardContent, Typography } from "@mui/material";

export const BlogCard = ({ post }) => {
  return (
    <Box sx={{ m: 1 }}>
      <CardActionArea
        component="a"
        href={`${process.env.REACT_APP_HOST_URL}/blog/${post._id}`}
      >
        <Card sx={{ display: "flex" }}>
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h5">
              {post.title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {`created at ${new Date(post.createdAt).toLocaleDateString()}`}
            </Typography>
            <Typography variant="subtitle1" paragraph>
              {post.description}
            </Typography>
          </CardContent>
        </Card>
      </CardActionArea>
    </Box>
  );
};
