import { Container } from "@mui/material";
import { Markdown } from "../Blog/Markdown";

export const Privacy = () => {
  const content = `
# Privacy Policy

Last updated: [09/08/2023]

This Privacy Policy outlines the types of information we collect and how we use, disclose, and protect that information. By accessing or using our website, you agree to the practices described in this policy.

## Information We Collect

We may collect personal information such as your name, email address, and other contact details when you subscribe to our newsletter, create an account, or interact with our website.

## How We Use Your Information

We use the information collected to:

- Provide you with relevant content, updates, and services.
- Improve our website based on your feedback and usage.
- Communicate with you regarding your inquiries and requests.
- Send you promotional offers and information about LastBenchScholar.

## Information Disclosure

We do not sell, trade, or transfer your personal information to third parties without your consent. However, we may share information with trusted service providers who assist us in operating our website, conducting business activities, or servicing you.

## Cookies and Tracking Technologies

We use cookies and similar tracking technologies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.

## Data Security

We take reasonable measures to protect your personal information from unauthorized access, alteration, or destruction. However, no data transmission over the internet is 100% secure, so we cannot guarantee absolute security.

## Your Choices

You have the right to:

- Access, correct, or delete your personal information.
- Opt-out of receiving promotional emails.
- Disable cookies through your browser settings.

## Changes to this Policy

We may update this Privacy Policy from time to time. Changes will be effective upon posting on our website.

## Contact Us

If you have any questions or concerns about our Privacy Policy, please contact us at [lastbenchscholar@gmail.com](mailto:lastbenchscholar@gmail.com).

Thank you for trusting LastBenchScholar with your information.

*LastBenchScholar Team*
`;
  return (
    <Container>
      <Markdown>{content}</Markdown>
    </Container>
  );
};
