import { useFetchData } from "../../hooks/useFetchData";
import { LoadingSpinner } from "../common/Spinner/LoadingSpinner";
import { Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { BlogCard } from "./BlogCard";

export const TagBlogs = () => {
  const { tag } = useParams();
  const { data, isLoading, error } = useFetchData(
    `${process.env.REACT_APP_SERVER_URL}/api/blogs/tag/${tag}`
  );

  if (isLoading) {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }

  if (error) {
    <Typography variant="h2" color={(theme) => theme.palette.error.main}>
      {error}
    </Typography>;
  }

  return (
    <Container maxWidth="lg">
      {data && data.map((post) => <BlogCard key={post._id} post={post} />)}

      {data?.length === 0 && (
        <Typography variant="h3" color="error" m={2}>
          Oops! No Blogs here
        </Typography>
      )}
    </Container>
  );
};
