import { useEffect, useState } from "react";

export const useFetchData = (url) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const refetch = () => {
      setIsLoading(true);
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            setError(`${response.status} ${response.statusText}`);
            setIsLoading(false);
            return;
          }
          response
            .json()
            .then((res) => {
              setData(res);
              setIsLoading(false);
            })
            .catch((err) => {
              setError(err.message);
              setIsLoading(false);
            });
        })
        .catch((err) => {
          setError(err.message);
          setIsLoading(false);
        });
    };
    refetch();
  }, [url]);

  return { data, isLoading, error };
};

// useEffect(() => {
//   fetch(`http://localhost:4000/api/blogs/${id}`)
//     .then((response) => {
//       if (!response.ok) {
//         console.log(response);
//         setError(true);
//       }
//       response
//         .json()
//         .then((res) => setBlog(res))
//         .catch((err) => console.log(err));
//     })
//     .catch((err) => console.log(err));
// }, [id]);
