import { Container, Typography, Box } from "@mui/material";
import { FeedbackForm } from "./FeedbackForm";

export const Feedback = () => {
  return (
    <Container>
      <Box
        sx={{
          display: { xs: "block", sm: "flex" },
          alignItems: "center",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Box>
          <Typography variant="h4">Feedback</Typography>
          <Typography variant="subtitle">
            Feel free to drop any feedback, ideas or suggestions.
          </Typography>
        </Box>
        <FeedbackForm />
      </Box>
    </Container>
  );
};
