import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./Home";
import { Layout } from "./components/common/Layout";
import { CreateBlog } from "./components/Blog/CreateBlog";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Blog } from "./components/Blog/Blog";
import { About } from "./components/footer/About";
import { Privacy } from "./components/footer/Privacy";
import { Contact } from "./components/footer/Contact";
import { Feedback } from "./components/footer/Feedback";
import { TagBlogs } from "./components/Blog/TagBlogs";
import { NotFound } from "./components/common/NotFound";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#0ea47a",
      light: "#12d39d",
      dark: "#0a7557",
      contrastText: "#ffffff",
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/create" element={<CreateBlog />} />
              <Route path="/blog/:id" element={<Blog />} />
              <Route path="/blogs/:tag" element={<TagBlogs />} />
              <Route path="/about" element={<About />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
