import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";

const sxField = {
  marginTop: "20px",
  marginBottom: "20px",
  display: "block",
  background: (theme) => theme.palette.common.white,
};

export const CreateBlog = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [category, setCategory] = useState("General");

  // Schema
  const schema = yup.object().shape({
    title: yup.string().max(100).required("Title is required!"),
    description: yup.string().max(1000),
    content: yup.string().max(65000).required("Content is required"),
  });

  // useForm
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleCreate = (data) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/api/blogs/`, {
      method: "POST",
      body: JSON.stringify({ ...data, tags: category }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setError(null);
        setSuccess(`"${res.title}" blog created successfully`);
        reset();
      })
      .catch((err) => setError(err.message));
  };
  return (
    <Box
      sx={{
        width: "50rem",
        marginRight: "auto",
        marginLeft: "auto",
        textAlign: "center",
      }}
    >
      <Typography variant="h5">Write a blog</Typography>
      <form noValidate autoComplete="off" onSubmit={handleSubmit(handleCreate)}>
        <TextField
          sx={sxField}
          label="Title"
          variant="standard"
          color="primary"
          fullWidth
          required
          error={errors.title ? true : false}
          helperText={errors.title?.message}
          {...register("title")}
        />
        <TextField
          sx={sxField}
          label="Description"
          variant="standard"
          color="primary"
          multiline
          fullWidth
          required
          error={errors.description ? true : false}
          helperText={errors.description?.message}
          {...register("description")}
        />
        <TextField
          sx={sxField}
          label="Content"
          variant="standard"
          color="primary"
          multiline
          rows={15}
          fullWidth
          required
          error={errors.content ? true : false}
          helperText={errors.content?.message}
          {...register("content")}
        />
        <FormControl sx={sxField}>
          <FormLabel>Blog Category</FormLabel>
          <RadioGroup value={category} onChange={(e) => setCategory(e.target.value)}>
            <FormControlLabel value="Tips" control={<Radio />} label="Tips" />
            <FormControlLabel value="Health" control={<Radio />} label="Health" />
            <FormControlLabel value="General" control={<Radio />} label="General" />
          </RadioGroup>
        </FormControl>
        <Button type="submit" color="primary" variant="contained" endIcon={<Send />}>
          Submit
        </Button>
      </form>
      {error && <Typography color="error">{error}</Typography>}
      {success && (
        <Typography
          sx={{
            color: (theme) => theme.palette.common.white,
            background: (theme) => theme.palette.success.light,
          }}
        >
          {success}
        </Typography>
      )}
    </Box>
  );
};
