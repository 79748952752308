import { Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Markdown } from "./Markdown";
import { LoadingSpinner } from "../common/Spinner/LoadingSpinner";
import { useFetchData } from "../../hooks/useFetchData";

export const Blog = () => {
  const { id } = useParams();
  const {
    data: blog,
    isLoading,
    error,
  } = useFetchData(`${process.env.REACT_APP_SERVER_URL}/api/blogs/${id}`);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <Typography variant="h2" color={(theme) => theme.palette.error.main}>
        {error}
      </Typography>
    );
  }

  return (
    <Container>
      {blog && (
        <>
          <Typography variant="h3">{blog.title}</Typography>
          <Markdown>{blog.content}</Markdown>
        </>
      )}
    </Container>
  );
};
