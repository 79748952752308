import { Container } from "@mui/material";
import { Navbar } from "./Navbar";
import { Footer } from "../footer/Footer";

export const Layout = ({ children }) => {
  return (
    <Container maxWidth="lg">
      <Navbar />
      <main>{children}</main>
      <Footer />
    </Container>
  );
};
