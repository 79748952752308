import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  AutoStoriesOutlined as AutoStoriesOutlinedIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const navItems = [
    { title: "Tips", href: "/blogs/Tips" },
    { title: "Health", href: "/blogs/Health" },
    { title: "General", href: "/blogs/General" },
    // { title: "Login", href: "/login" },
    // { title: "Register", href: "/register" },
  ];

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        background: (theme) => theme.palette.common.white,
        borderBottom: 1,
        borderColor: "divider",
        mb: 1,
      }}
    >
      <Toolbar sx={{ pr: { xs: "0px", sm: "24px" }, pl: { xs: "0px", sm: "24px" } }}>
        <Box
          sx={{ color: "#111111", display: { xs: "none", sm: "flex" }, width: "100%" }}
        >
          <Link
            sx={{
              display: "flex",
              alignItems: "center",
              userSelect: "none",
              textDecoration: "none",
              color: "inherit",
              mr: "auto",
            }}
            href={process.env.REACT_APP_HOST_URL}
          >
            <AutoStoriesOutlinedIcon />
            <Typography component="div" variant="h6" ml={1} noWrap={true}>
              Last Bench Scholar
            </Typography>
          </Link>

          <Stack direction="row" spacing={1}>
            {navItems &&
              navItems.map((item) => (
                <Button
                  key={item.title}
                  color="inherit"
                  onClick={() => navigate(item.href)}
                >
                  {item.title}
                </Button>
              ))}
          </Stack>
        </Box>

        <Box sx={{ display: { xs: "flex", sm: "none" }, color: "#111111" }}>
          <IconButton
            sx={{ mr: { sm: 2 } }}
            size="large"
            color="inherit"
            onClick={() => setOpenDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Link
            sx={{
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              userSelect: "none",
              ml: "auto",
              color: "inherit",
            }}
            href={process.env.REACT_APP_HOST_URL}
          >
            <AutoStoriesOutlinedIcon />
            <Typography component="div" variant="h6" ml={1} noWrap={true}>
              Last Bench Scholar
            </Typography>
          </Link>
        </Box>

        <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
          <Box p={2} width="250px" textAlign="center">
            <Typography
              variant="h6"
              component="div"
              onClick={() => {
                navigate("/");
                setOpenDrawer(false);
              }}
            >
              Last Bench Scholar
            </Typography>
            <Stack direction="column" spacing={1}>
              {navItems &&
                navItems.map((item) => (
                  <Button
                    color="inherit"
                    key={item.title}
                    onClick={() => {
                      setOpenDrawer(false);
                      navigate(item.href);
                    }}
                  >
                    {item.title}
                  </Button>
                ))}
            </Stack>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};
