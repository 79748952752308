import { Container, Grid, Typography } from "@mui/material";
import { LandingSection } from "./components/common/LandingSection";
import { Sidebar } from "./components/common/Sidebar";
import { Main } from "./components/Blog/Main";
import { useFetchData } from "./hooks/useFetchData";
import { LoadingSpinner } from "./components/common/Spinner/LoadingSpinner";

export const Home = () => {
  const { data, isLoading, error } = useFetchData(
    `${process.env.REACT_APP_SERVER_URL}/api/blogs/`
  );

  if (isLoading) {
    return (
      <>
        <LandingSection />
        <LoadingSpinner />
      </>
    );
  }

  if (error) {
    <Typography variant="h2" color={(theme) => theme.palette.error.main}>
      {error}
    </Typography>;
  }

  return (
    <Container maxWidth="lg">
      <LandingSection />
      <Grid container spacing={5} sx={{ mt: 3 }}>
        <Main title="Trending" blogs={data} />
        <Sidebar />
      </Grid>
    </Container>
  );
};
